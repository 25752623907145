/* eslint-disable @typescript-eslint/no-unused-vars */
import * as React from "react";
import { debounce } from "debounce";
import { Col, Modal, ProgressBar, Row } from "react-bootstrap";
// import { MultiStepProgressBar } from "../MultiStepProgressBar/MultiStepProgressBar";
import {
  FormContainer,
  InputComponent,
  Label,
  OptionBtn,
  QuestionText,
  Text,
} from "./styled";
import { BackIcon, CircleCross } from "../../Assets";
import {
  createLeads,
  getUniversity,
  searchCountries,
  searchUniversities,
} from "../../Services/Function";
import { Form, Formik, FormikHelpers } from "formik";
import { FormInput } from "../FormInput";
import { CustomCheckbox } from "../CustomCheckbox";

import PhoneInput, { formatPhoneNumberIntl } from "react-phone-number-input";
import { getCountries } from "react-phone-number-input";
import { ButtonComponent } from "../ButtonComponent";
import { useNavigate } from "react-router-dom";
import { ErrorMessage } from "../../pages/styled/result";
import { useTranslation } from "react-i18next";
// import { PhonenoComponent } from "../PhonenoComponent";
import { UniversityInputComponent } from "../UniversityInputComponet";
import { CountryInputComponent } from "../CountryInputComponent";
import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { dataAction } from "../../state";
import { useState } from "react";
import { BadgeCon, BadgeText } from "../UniversityInputComponet/styled";

interface IModalComponentProps {
  show: boolean;
  onHide: Function;
}

export const ModalComponent: React.FunctionComponent<IModalComponentProps> = (
  props
) => {
  interface Values {
    email: string;
    residence: string;
    firstName: string;
    lastName: string;
  }
  const { t, i18n } = useTranslation();
  const [page, setPage] = React.useState("1");
  const [countries, setcountries] = React.useState([]);
  const [selectedCountry, setselectedCountry] = React.useState<any>({});
  const [selectedResidence, setselectedResidence] = React.useState<any>({});
  const [accomplished, setaccomplished] = React.useState<any>(["pageone"]);
  const [sponsored, setsponsored] = useState<any>(null);
  const [sponsoredBy, setsponsoredBy] = useState("");
  // const [subjects, setsubjects] = React.useState([]);
  const [universities, setuniversities] = React.useState([]);
  const [selectedUniversity, setselectedUniversity] = React.useState([]);
  const [selectedSubject, setselectedSubject] = React.useState<any>([]);
  const [searchText, setsearchText] = React.useState("");
  const [destination, setdestination] = React.useState<any>({});
  const [degree, setdegree] = React.useState<any>({});
  const [loading, setloading] = React.useState(false);
  const [takenIltes, settakenIltes] = React.useState<any>(null);
  const [iltesScore, setiltesScore] = React.useState("");
  const [cgpa, setcgpa] = React.useState("");
  const [loadingUni, setloadingUni] = useState(false);
  const [previousSchool, setpreviousSchool] = React.useState("");
  const [moreThan50000, setmoreThan50000] = React.useState<any>(null);
  const [engLevel, setengLevel] = React.useState("");
  const [countryVisited, setcountryVisited] = React.useState<any>([
    { country: "europe", key: 3, status: false },
    { country: "australia", key: 0, status: false },
    { country: "america", key: 1, status: false },
    { country: "uk", key: 2, status: false },
    { country: "notVisited", key: 4, status: false },
  ]);
  const [recommendedCountries, setrecommendedCountries] = useState([
    {
      english_name: "Kuwait",
      arabic_name: "الكويت",
      alpha2_code: "KW",
      alpha3_code: "KWT",
      phone_code: "965",
      id: "63cfb299df954c47a6fde217",
    },
    {
      english_name: "Saudi Arabia",
      arabic_name: "السعودية",
      alpha2_code: "SA",
      alpha3_code: "SAU",
      phone_code: "966",
      id: "63cfb299df954c47a6fde264",
    },
    {
      english_name: "Qatar",
      arabic_name: "قطر",
      alpha2_code: "QA",
      alpha3_code: "QAT",
      phone_code: "974",
      id: "63cfb299df954c47a6fde255",
    },
    {
      english_name: "Oman",
      arabic_name: "سلطنة عمان",
      alpha2_code: "OM",
      alpha3_code: "OMN",
      phone_code: "968",
      id: "63cfb299df954c47a6fde248",
    },
    {
      english_name: "United Arab Emirates",
      arabic_name: "الإمارات العربية المتحدة",
      alpha2_code: "AE",
      alpha3_code: "ARE",
      phone_code: "971",
      id: "63cfb299df954c47a6fde28d",
    },
  ]);
  const [universitiesRe, setuniversitiesRe] = useState({});
  const [phoneNo, setphoneNo] = React.useState("");
  const [errorMessage, seterrorMessage] = React.useState("");
  const [majors, setmajors] = React.useState("");
  const [checkQualified, setcheckQualified] = useState<any>(true);

  const arabic = i18n.language === "ar";
  const nextPage = (page: string) => {
    setPage(page);
  };
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const { showAnimation } = bindActionCreators(dataAction, dispatch);

  React.useEffect(() => {
    async function fetchData() {
      setloading(true);
      const res = await getUniversity();

      setuniversitiesRe(res.results);
      setloading(false);
    }
    fetchData();
  }, []);

  // const handleRemoveItem = (e: any) => {
  //   const name = e;
  //   setselectedSubject(selectedSubject.filter((item: any) => item !== name));
  // };
  const onChangeDestination = (data: any) => {
    accomplished.push("pageone");
    nextPage("2");
    setdestination(data);
    if (data.en_name === "Others") {
      setcheckQualified(false);
    } else {
      setcheckQualified(true);
    }
  };
  const onChangeDegree = (data: object) => {
    setdegree(data);
  };

  const searchCountry = debounce(async (e: any) => {
    if (!e.target.value) {
      setcountries([]);
    } else {
      setloading(true);
      const res = await searchCountries({
        text: e.target.value,
        sortBy: "createdAt:desc",
        limit: 7,
      });
      setcountries(res?.results);
      setloading(false);
    }
  }, 500);

  const searchUniversity = debounce(async (e: any) => {
    if (!e.target.value) {
      setuniversities([]);
    } else {
      setloadingUni(true);
      const res: any = await searchUniversities({
        text: e.target.value,
        sortBy: "createdAt:desc",
        limit: 5,
      });
      setuniversities(res?.results);
      setloadingUni(false);
    }
  }, 500);

  let checkCountryVisited = countryVisited
    .filter((a: any) => a.status === true)
    .some(
      (item: any) =>
        item.country === "uk" ||
        item.country === "america" ||
        item.country === "europe" ||
        item.country === "australia"
    );

  const resetValues = () => {
    setaccomplished([]);
    setmajors("");
    setsearchText("");
    // setsearchText1("");
    setcountries([]);
    setuniversities([]);
    setcountryVisited([
      { country: "europe", key: 3, status: false },
      { country: "australia", key: 0, status: false },
      { country: "america", key: 1, status: false },
      { country: "uk", key: 2, status: false },
      { country: "notVisited", key: 4, status: false },
    ]);
    setselectedCountry({});
    setselectedUniversity([]);
    setpreviousSchool("");
    setphoneNo("");
    setcgpa("");
    setselectedResidence({});
  };

  const excludedCountries = ["IL"]; // Countries to exclude

  const filteredCountries = getCountries().filter(
    (country) => !excludedCountries.includes(country)
  );

  const contryCheckQualified = (country: any) => {
    console.log(country, "hjgfd");
    return (
      country.english_name === "United States of America" ||
      country.english_name === "Australia" ||
      country.english_name === "Oman" ||
      country.english_name === "France" ||
      country.english_name === "Spain" ||
      country.english_name === "United Kingdom" ||
      country.english_name === "Qatar" ||
      country.english_name === "Kuwait" ||
      country.english_name === "Saudi Arabia" ||
      country.english_name === "United Arab Emirates" ||
      country.english_name === "Bahrain" ||
      country.english_name === "Germany"
    );
  };

  console.log(checkQualified);

  return (
    <Modal show={props.show} fullscreen={true} onHide={() => props.onHide()}>
      <Modal.Header style={{ borderBottom: "none", width: "100%" }}>
        {/* <Modal.Title className="w-100 d-flex justify-content-between">
          <div />
          <MultiStepProgressBar
            page={page}
            selectedCountry={checkQualified}
            accomplished={accomplished}
          />
          <div
            onClick={() => {
              setPage("1");
              setaccomplished([]);
              setselectedSubject([]);
              setcountryVisited([
                { country: "أوروبا", key: 3, status: false },
                { country: "أستراليا", key: 0, status: false },
                { country: "أمريكا أو كندا", key: 1, status: false },
                { country: " المملكة المتحدة ", key: 2, status: false },
              ]);
              setselectedCountry("");
              setpreviousSchool("");
              setphoneNo("");
              setcgpa("");
              props.onHide();
            }}
          >
            <CircleCross style={{ width: 28, height: 28, cursor: "pointer" }} />
          </div>
        </Modal.Title> */}
        <Modal.Title className="w-100 d-flex justify-content-between align-items-center">
          {page === "1" ? (
            <div />
          ) : (
            <div
              className="d-flex align-items-center"
              style={{ cursor: "pointer" }}
              onClick={() => {
                if (!checkQualified && !moreThan50000 && page === "9") {
                  setPage(`${parseInt(page) - 2}`);
                  setsponsored(null);
                  setsponsoredBy("");
                } else {
                  setPage(`${parseInt(page) - 1}`);
                }
              }}
            >
              <BackIcon />
            </div>
          )}
          <ProgressBar
            now={
              !checkQualified
                ? parseInt(page) * 11
                : !checkQualified && moreThan50000
                ? parseInt(page) * 10
                : checkQualified
                ? parseInt(page) * 14.28
                : 0
            }
          />
          <div
            onClick={() => {
              setPage("1");
              resetValues();
              props.onHide();
            }}
            className="d-flex align-items-center"
          >
            <CircleCross style={{ width: 28, height: 28, cursor: "pointer" }} />
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className=" d-flex justify-content-center align-items-center">
        {!checkQualified ? (
          <div className="w-100 res-con-width">
            {page === "1" ? (
              <div className="d-flex w-100 flex-column justify-content-center align-items-center">
                <QuestionText className="  " arabic={arabic}>
                  {t("common:question1")}
                </QuestionText>
                <div
                  className="d-flex flex-row w-100 justify-content-center"
                  style={{
                    paddingBottom: 15,
                    direction: arabic ? "rtl" : "ltr",
                  }}
                >
                  <OptionBtn
                    className="  "
                    arabic={arabic}
                    justifyContent="space-between"
                    style={{
                      color:
                        destination.en_name === "UK" ? "#FD3E60" : "#191d23",
                      backgroundColor:
                        destination.en_name === "UK"
                          ? "#FD3E6014"
                          : "#E8E9F5B2",
                      width: "100%",
                      direction: arabic ? "rtl" : "ltr",
                      margin: !arabic ? "0px 20px 0 0" : "0px 0 0 20px",
                    }}
                    onClick={() =>
                      onChangeDestination({
                        ar_name: "بريطانيا",
                        en_name: "UK",
                      })
                    }
                  >
                    <div />
                    <div
                      style={{
                        fontSize: 24,
                        position: "relative",
                        left: arabic ? 10 : 0,
                        right: arabic ? 0 : 10,
                      }}
                    >
                      🇬🇧
                    </div>{" "}
                    {t("common:uk")}
                  </OptionBtn>
                  <OptionBtn
                    className="  "
                    arabic={arabic}
                    justifyContent="space-between"
                    style={{
                      color:
                        destination.en_name === "US" ? "#FD3E60" : "#191d23",
                      backgroundColor:
                        destination.en_name === "US"
                          ? "#FD3E6014"
                          : "#E8E9F5B2",
                      width: "100%",
                      marginRight: 0,
                    }}
                    onClick={() =>
                      onChangeDestination({ ar_name: "أمريكا", en_name: "US" })
                    }
                  >
                    <div />
                    <div
                      style={{
                        fontSize: 24,
                        position: "relative",
                        left: arabic ? 10 : 0,
                        right: arabic ? 0 : 10,
                      }}
                    >
                      🇺🇸
                    </div>{" "}
                    {t("common:us")}
                  </OptionBtn>
                </div>
                <div className="d-flex flex-row w-100 justify-content-center">
                  <OptionBtn
                    className="  "
                    arabic={arabic}
                    justifyContent="space-between"
                    style={{
                      color:
                        destination.en_name === "Australia"
                          ? "#FD3E60"
                          : "#191d23",
                      backgroundColor:
                        destination.en_name === "Australia"
                          ? "#FD3E6014"
                          : "#E8E9F5B2",
                      width: "100%",
                      direction: arabic ? "rtl" : "ltr",
                      margin: arabic ? "0px 20px 0 0" : "0px 0 0 20px",
                    }}
                    onClick={() =>
                      onChangeDestination({
                        ar_name: "أستراليا",
                        en_name: "Australia",
                      })
                    }
                  >
                    <div />
                    <div
                      style={{
                        fontSize: 24,
                        position: "relative",
                        left: arabic ? 10 : 0,
                        right: arabic ? 0 : 10,
                      }}
                    >
                      🇦🇺
                    </div>{" "}
                    {t("common:aus")}
                  </OptionBtn>
                  <OptionBtn
                    className="  "
                    arabic={arabic}
                    justifyContent="space-between"
                    style={{
                      color:
                        destination.en_name === "Others"
                          ? "#FD3E60"
                          : "#191d23",
                      backgroundColor:
                        destination.en_name === "Others"
                          ? "#FD3E6014"
                          : "#E8E9F5B2",
                      width: "100%",
                      marginRight: 0,
                      direction: arabic ? "rtl" : "ltr",
                    }}
                    onClick={() =>
                      onChangeDestination({
                        ar_name: "غيرها",
                        en_name: "Others",
                      })
                    }
                  >
                    <div />
                    <div
                      style={{
                        fontSize: 24,
                        position: "relative",
                        left: arabic ? 10 : 0,
                        right: arabic ? 0 : 10,
                      }}
                    >
                      🌏
                    </div>{" "}
                    {t("common:other")}
                  </OptionBtn>
                </div>
              </div>
            ) : page === "2" ? (
              <div className="d-flex flex-column justify-content-center align-items-center">
                <QuestionText className=" " arabic={arabic}>
                  {t("common:question2")}
                </QuestionText>

                <CountryInputComponent
                  selectedCountry={selectedCountry}
                  loading={loading}
                  setsearchText={setsearchText}
                  setselectedCountry={setselectedCountry}
                  arabic={arabic}
                  onClickNext={() => {
                    if (selectedCountry) {
                      accomplished.push("pagetwo");
                      nextPage("3");
                      seterrorMessage("");
                      setcountries([]);
                      setsearchText("");

                      setcheckQualified(contryCheckQualified(selectedCountry));
                    } else {
                      seterrorMessage("Select country to continue");
                    }
                  }}
                  recommendedCountries={recommendedCountries.slice(0, 5)}
                  searchCountry={searchCountry}
                  countries={countries}
                  setcountries={(a: any) => setcountries(a)}
                  searchText={searchText}
                  placeholder={`${t("common:nationalityPlaceholder")}`}
                  setRecommendedCountries={setrecommendedCountries}
                />

                {errorMessage ? (
                  <ErrorMessage>{errorMessage}</ErrorMessage>
                ) : null}
              </div>
            ) : page === "3" ? (
              <div className="d-flex flex-column justify-content-center align-items-center">
                <QuestionText arabic={arabic}>
                  {t("common:question3")}
                </QuestionText>

                <div
                  className="d-flex flex-row w-100 justify-content-center"
                  style={{
                    paddingBottom: 15,
                    direction: arabic ? "rtl" : "ltr",
                  }}
                >
                  <OptionBtn
                    className=" "
                    justifyContent="center"
                    arabic={arabic}
                    style={{
                      color:
                        degree.en_name === "Bachelor's" ? "#FD3E60" : "#191d23",
                      backgroundColor:
                        degree.en_name === "Bachelor's"
                          ? "#FD3E6014"
                          : "#E8E9F5B2",
                      width: "100%",
                      margin: !arabic ? "0" : "0px 0 0 20px",
                    }}
                    onClick={() =>
                      onChangeDegree({
                        en_name: "Bachelor's",
                        ar_name: "البكلوريوس",
                      })
                    }
                  >
                    {t("common:bachelor")}
                  </OptionBtn>
                  <OptionBtn
                    className=" "
                    style={{
                      color:
                        degree.en_name === "Master's" ? "#FD3E60" : "#191d23",
                      backgroundColor:
                        degree.en_name === "Master's"
                          ? "#FD3E6014"
                          : "#E8E9F5B2",
                      width: "100%",
                      margin: arabic ? "0" : "0px 0 0 20px",
                    }}
                    justifyContent="center"
                    arabic={arabic}
                    onClick={() =>
                      onChangeDegree({
                        en_name: "Master's",
                        ar_name: "الماجستير",
                      })
                    }
                  >
                    {t("common:masters")}
                  </OptionBtn>
                </div>
                <div
                  style={{ direction: arabic ? "rtl" : "ltr" }}
                  className="d-flex flex-row w-100 justify-content-center"
                >
                  <OptionBtn
                    className=" "
                    style={{
                      color: degree.en_name === "PhD" ? "#FD3E60" : "#191d23",
                      backgroundColor:
                        degree.en_name === "PhD" ? "#FD3E6014" : "#E8E9F5B2",
                      width: "100%",
                      margin: !arabic ? "0" : "0px 0 0 20px",
                    }}
                    justifyContent="center"
                    arabic={arabic}
                    onClick={() =>
                      onChangeDegree({
                        en_name: "PhD",
                        ar_name: "الدكتوراة",
                      })
                    }
                  >
                    {t("common:phd")}
                  </OptionBtn>
                  <OptionBtn
                    className=" "
                    justifyContent="center"
                    style={{
                      color:
                        degree.en_name === "English Language"
                          ? "#FD3E60"
                          : "#191d23",
                      backgroundColor:
                        degree.en_name === "English Language"
                          ? "#FD3E6014"
                          : "#E8E9F5B2",
                      width: "100%",
                      margin: arabic ? "0" : "0px 0 0 20px",
                    }}
                    arabic={arabic}
                    onClick={() =>
                      onChangeDegree({
                        en_name: "English Language",
                        ar_name: "لغة انجليزية",
                      })
                    }
                  >
                    {t("common:english_lang")}
                  </OptionBtn>
                </div>
                {Object.keys(degree).length ? (
                  <div
                    style={{ paddingTop: 40 }}
                    className="d-flex  w-100 flex-column justify-content-center align-items-center"
                  >
                    <QuestionText
                      arabic={arabic}
                      style={{
                        marginBottom: 15,
                        textAlign: arabic ? "right" : "left",
                        width: "100%",
                        fontWeight: 500,
                      }}
                    >
                      {t("common:question4")}
                    </QuestionText>
                    <InputComponent
                      placeholder={`${t("common:majorPlaceholder")}`}
                      name="name"
                      onChange={(e: any) => setmajors(e.target.value)}
                      type="text"
                      value={majors}
                      arabic={arabic}
                      autoComplete="off"
                    />
                    {errorMessage ? (
                      <ErrorMessage>{errorMessage}</ErrorMessage>
                    ) : null}
                    {majors ? (
                      <ButtonComponent
                        label={t("common:continue")}
                        onClick={() => {
                          setcountries([]);
                          if (majors) {
                            accomplished.push("pagethree");
                            nextPage("4");
                            seterrorMessage("");
                          } else {
                            seterrorMessage("Select subject to continue");
                          }
                        }}
                        loading={false}
                        width="392px"
                        btnType={"dark"}
                        style={{ marginTop: 36, height: 56 }}
                      />
                    ) : null}
                  </div>
                ) : null}
              </div>
            ) : page === "4" ? (
              <div className="d-flex w-100 flex-column justify-content-center align-items-center">
                <QuestionText arabic={arabic}>
                  {t("common:question5")}
                </QuestionText>

                <InputComponent
                  placeholder={`${t("common:gradePlaceholder")}`}
                  name="name"
                  onChange={(e: any) => setcgpa(e.target.value)}
                  type="text"
                  autoComplete="off"
                  value={cgpa}
                  arabic={arabic}
                />
                {cgpa ? null : (
                  <OptionBtn
                    className=""
                    justifyContent="center"
                    marginTop="15px"
                    arabic={arabic}
                    width={"max-content"}
                    style={{
                      marginTop: 36,
                      paddingLeft: "1rem",
                      paddingRight: "1rem",
                    }}
                    onClick={() => {
                      setcgpa("Not Sure");
                      accomplished.push("pagefour");
                      nextPage("5");
                      seterrorMessage("");
                    }}
                  >
                    {t("common:notSure")}
                  </OptionBtn>
                )}

                {errorMessage ? (
                  <ErrorMessage>{errorMessage}</ErrorMessage>
                ) : null}
                {cgpa ? (
                  <ButtonComponent
                    className=" "
                    label={t("common:continue")}
                    onClick={() => {
                      if (cgpa) {
                        accomplished.push("pagefour");
                        nextPage("5");
                        seterrorMessage("");
                        // setcgpa("");
                      } else {
                        seterrorMessage("Answer the question to continue");
                      }
                    }}
                    loading={loading}
                    btnType={"dark"}
                    width="392px"
                    style={{ marginTop: 36, height: 56 }}
                  />
                ) : null}
              </div>
            ) : page === "5" ? (
              <div className="d-flex  w-100 flex-column justify-content-center align-items-center">
                <QuestionText arabic={arabic}>
                  {t("common:question6NotQualified")}
                </QuestionText>
                <InputComponent
                  placeholder={`${t("common:schoolPlaceholder")}`}
                  name="previousSchool"
                  onChange={(e: any) => setpreviousSchool(e.target.value)}
                  type="text"
                  value={previousSchool}
                  autoComplete="off"
                  arabic={arabic}
                />
                {previousSchool ? null : (
                  <OptionBtn
                    className=""
                    justifyContent="center"
                    marginTop="15px"
                    arabic={arabic}
                    width={"max-content"}
                    style={{
                      marginTop: 36,
                      paddingLeft: "1rem",
                      paddingRight: "1rem",
                    }}
                    onClick={() => {
                      setpreviousSchool("Not Sure");
                      accomplished.push("pagefive");
                      nextPage("6");
                      seterrorMessage("");
                    }}
                  >
                    {t("common:notSure")}
                  </OptionBtn>
                )}
                {errorMessage ? (
                  <ErrorMessage>{errorMessage}</ErrorMessage>
                ) : null}
                {previousSchool ? (
                  <ButtonComponent
                    className=" "
                    label={t("common:continue")}
                    onClick={() => {
                      if (previousSchool) {
                        accomplished.push("pagefive");
                        nextPage("6");
                        seterrorMessage("");
                      } else {
                        seterrorMessage("Answere the question to continue");
                      }
                    }}
                    loading={loading}
                    btnType={"dark"}
                    width="392px"
                    style={{ marginTop: 36, height: 56 }}
                  />
                ) : null}
              </div>
            ) : page === "6" ? (
              <div className="d-flex flex-column justify-content-center align-items-center">
                <QuestionText arabic={arabic} className="">
                  {t("common:forVisaPurpose")}
                </QuestionText>
                <QuestionText arabic={arabic}>
                  {t("common:question7NotQualified")}
                </QuestionText>

                <div
                  style={{ direction: arabic ? "rtl" : "ltr" }}
                  className="d-flex flex-row w-100 justify-content-center"
                >
                  <OptionBtn
                    className=""
                    style={{
                      color: moreThan50000 === true ? "#FD3E60" : "#191d23",
                      backgroundColor:
                        moreThan50000 === true ? "#FD3E6014" : "#E8E9F5B2",
                      width: "100%",
                      margin: !arabic ? "0" : "0px 0 0 20px",
                    }}
                    arabic={arabic}
                    justifyContent="center"
                    onClick={() => {
                      accomplished.push("pagesix");
                      nextPage("7");
                      setmoreThan50000(true);
                    }}
                  >
                    {t("common:yes")}
                  </OptionBtn>
                  <OptionBtn
                    className=" "
                    arabic={arabic}
                    style={{
                      color: moreThan50000 === false ? "#FD3E60" : "#191d23",
                      backgroundColor:
                        moreThan50000 === false ? "#FD3E6014" : "#E8E9F5B2",
                      width: "100%",
                      margin: arabic ? "0" : "0px 0 0 20px",
                    }}
                    justifyContent="center"
                    onClick={() => {
                      accomplished.push("pagesix");
                      nextPage("7");
                      setmoreThan50000(false);
                      setsponsored(null);
                      setsponsoredBy("");
                      setiltesScore("");
                    }}
                  >
                    {t("common:no")}
                  </OptionBtn>
                </div>
              </div>
            ) : page === "7" ? (
              <div className="d-flex flex-column justify-content-center align-items-center">
                <QuestionText className=" " arabic={arabic}>
                  {t("common:forVisaPurpose")}
                </QuestionText>
                <QuestionText className=" " arabic={arabic}>
                  {t("common:question8NotQualified")}
                </QuestionText>
                {countryVisited.map((a: any, key: any) => (
                  <CustomCheckbox
                    key={key}
                    label={t(`common:${a.country}`)}
                    arabic={arabic}
                    onChange={() => {
                      if (a.key !== 4 && a.status === false) {
                        const data = countryVisited.map((x: any) => {
                          if (x.key === a.key) {
                            x.status = true;
                          } else if (x.key === 4) {
                            x.status = false;
                          }
                          return x;
                        });
                        setcountryVisited(data);
                      } else if (a.key === 4 && a.status === false) {
                        const data = countryVisited.map((x: any) => {
                          if (x.key === a.key) {
                            x.status = true;
                          } else {
                            x.status = false;
                          }
                          return x;
                        });
                        setcountryVisited(data);
                      } else {
                        const data = countryVisited.map((x: any) => {
                          if (x.key === a.key) {
                            x.status = false;
                          }
                          return x;
                        });
                        setcountryVisited(data);
                      }
                    }}
                    checked={a.status === true}
                  />
                ))}

                {countryVisited.length ? (
                  <ButtonComponent
                    className=" "
                    label={t("common:continue")}
                    onClick={() => {
                      if (
                        countryVisited.some(
                          (item: any) => item.status === true
                        ) &&
                        moreThan50000
                      ) {
                        nextPage("8");
                      } else {
                        nextPage("9");
                      }
                    }}
                    loading={loading}
                    btnType={"dark"}
                    width="392px"
                    style={{ marginTop: 36, height: 56 }}
                  />
                ) : null}
              </div>
            ) : page === "8" && moreThan50000 ? (
              <div className="d-flex flex-column justify-content-center align-items-center">
                <>
                  <QuestionText arabic={arabic}>
                    {t("common:question9Qualified")}
                  </QuestionText>
                  <div
                    style={{ direction: arabic ? "rtl" : "ltr" }}
                    className="d-flex flex-row w-100 justify-content-center"
                  >
                    <OptionBtn
                      className=""
                      // selected={takenIltes}
                      arabic={arabic}
                      justifyContent="center"
                      onClick={() => {
                        settakenIltes(true);
                      }}
                      style={{
                        color: takenIltes === true ? "#FD3E60" : "#191d23",
                        backgroundColor:
                          takenIltes === true ? "#FD3E6014" : "#E8E9F5B2",
                        width: "100%",
                        margin: !arabic ? "0" : "0px 0 0 20px",
                      }}
                    >
                      {t("common:yes")}
                    </OptionBtn>
                    <OptionBtn
                      className=" "
                      arabic={arabic}
                      justifyContent="center"
                      onClick={() => {
                        settakenIltes(false);
                      }}
                      style={{
                        color: takenIltes === false ? "#FD3E60" : "#191d23",
                        backgroundColor:
                          takenIltes === false ? "#FD3E6014" : "#E8E9F5B2",
                        width: "100%",
                        margin: arabic ? "0" : "0px 0 0 20px",
                      }}
                    >
                      {t("common:no")}
                    </OptionBtn>
                  </div>
                </>
                {takenIltes === false ? (
                  <>
                    <QuestionText
                      style={{
                        marginBottom: 15,
                        paddingTop: 40,
                        textAlign: arabic ? "right" : "left",
                        width: "100%",
                        fontWeight: 500,
                      }}
                      arabic={arabic}
                    >
                      {t("common:whatsYourEngLevel")}
                    </QuestionText>

                    <div
                      style={{
                        paddingBottom: 20,
                        direction: arabic ? "rtl" : "ltr",
                      }}
                      className="d-flex flex-row w-100 justify-content-center"
                    >
                      <OptionBtn
                        className=""
                        // selected={takenIltes}
                        arabic={arabic}
                        style={{
                          color:
                            engLevel === "beginner" ? "#FD3E60" : "#191d23",
                          backgroundColor:
                            engLevel === "beginner" ? "#FD3E6014" : "#E8E9F5B2",
                          width: "100%",
                          margin: !arabic ? "0" : "0px 0 0 20px",
                        }}
                        justifyContent="center"
                        onClick={() => {
                          setengLevel("beginner");
                          accomplished.push("pageeight");
                          nextPage("9");
                        }}
                      >
                        {t("common:beginner")}
                      </OptionBtn>
                      <OptionBtn
                        className=" "
                        arabic={arabic}
                        style={{
                          color:
                            engLevel === "lowerIntermediate"
                              ? "#FD3E60"
                              : "#191d23",
                          backgroundColor:
                            engLevel === "lowerIntermediate"
                              ? "#FD3E6014"
                              : "#E8E9F5B2",
                          margin: arabic ? "0" : "0px 0 0 20px",
                          width: "100%",
                        }}
                        justifyContent="center"
                        onClick={() => {
                          setengLevel("lowerIntermediate");
                          accomplished.push("pageeight");
                          nextPage("9");
                        }}
                      >
                        {t("common:lowerIntermediate")}
                      </OptionBtn>
                    </div>
                    <div
                      style={{
                        paddingBottom: 20,
                        direction: arabic ? "rtl" : "ltr",
                      }}
                      className="d-flex flex-row w-100 justify-content-center"
                    >
                      <OptionBtn
                        className=" "
                        arabic={arabic}
                        style={{
                          color:
                            engLevel === "intermediate" ? "#FD3E60" : "#191d23",
                          backgroundColor:
                            engLevel === "intermediate"
                              ? "#FD3E6014"
                              : "#E8E9F5B2",
                          width: "100%",
                          margin: !arabic ? "0" : "0px 0 0 20px",
                        }}
                        justifyContent="center"
                        onClick={() => {
                          setengLevel("intermediate");
                          accomplished.push("pageeight");
                          nextPage("9");
                        }}
                      >
                        {t("common:intermediate")}
                      </OptionBtn>
                      <OptionBtn
                        className=" "
                        arabic={arabic}
                        justifyContent="center"
                        style={{
                          color:
                            engLevel === "upperIntermediate"
                              ? "#FD3E60"
                              : "#191d23",
                          backgroundColor:
                            engLevel === "upperIntermediate"
                              ? "#FD3E6014"
                              : "#E8E9F5B2",
                          width: "100%",
                          margin: arabic ? "0" : "0px 0 0 20px",
                        }}
                        onClick={() => {
                          setengLevel("upperIntermediate");
                          accomplished.push("pageeight");
                          nextPage("9");
                        }}
                      >
                        {t("common:upperIntermediate")}
                      </OptionBtn>
                    </div>
                    <div
                      style={{
                        paddingBottom: 20,
                        direction: arabic ? "rtl" : "ltr",
                      }}
                      className="d-flex flex-row w-100 justify-content-center"
                    >
                      <OptionBtn
                        className=" "
                        arabic={arabic}
                        justifyContent="center"
                        style={{
                          color:
                            engLevel === "advanced" ? "#FD3E60" : "#191d23",
                          backgroundColor:
                            engLevel === "advanced" ? "#FD3E6014" : "#E8E9F5B2",
                          width: "100%",
                          margin: !arabic ? "0" : "0px 0 0 20px",
                        }}
                        onClick={() => {
                          setengLevel("advanced");
                          accomplished.push("pageeight");
                          nextPage("9");
                        }}
                      >
                        {t("common:advanced")}
                      </OptionBtn>
                      <OptionBtn
                        className=" "
                        arabic={arabic}
                        style={{
                          opacity: 0,
                          width: "100%",
                          margin: arabic ? "0" : "0px 0 0 20px",
                        }}
                        justifyContent="center"
                        onClick={() => {}}
                      >
                        {t("common:advanced")}
                      </OptionBtn>
                    </div>
                  </>
                ) : takenIltes === true ? (
                  <div className=" w-100" style={{ paddingTop: 40 }}>
                    <QuestionText
                      style={{
                        marginBottom: 15,
                        textAlign: arabic ? "right" : "left",
                        width: "100%",
                        fontWeight: 500,
                      }}
                      arabic={arabic}
                    >
                      {t("common:whatsYourScore")}
                    </QuestionText>
                    <InputComponent
                      placeholder={``}
                      name="iltesScore"
                      onChange={(e: any) => setiltesScore(e.target.value)}
                      type="number"
                      value={iltesScore}
                      autoComplete="off"
                      arabic={arabic}
                    />
                    <ButtonComponent
                      className=" "
                      disableBtnStyle={!iltesScore}
                      label={t("common:continue")}
                      onClick={() => {
                        accomplished.push("pageeight");
                        nextPage("9");
                      }}
                      loading={loading}
                      btnType={"dark"}
                      width="392px"
                      style={{ marginTop: 36, height: 56 }}
                    />
                  </div>
                ) : null}
              </div>
            ) : page === "9" ? (
              <div className="d-flex flex-column justify-content-center align-items-center">
                <>
                  <QuestionText arabic={arabic}>
                    {t("common:sponsorQuestion")}
                  </QuestionText>

                  <div
                    style={{ direction: arabic ? "rtl" : "ltr" }}
                    className="d-flex flexrow w-100"
                  >
                    <OptionBtn
                      className=""
                      // selected={takenIltes}
                      arabic={arabic}
                      justifyContent="center"
                      style={{
                        color: sponsored ? "#FD3E60" : "#191d23",
                        backgroundColor: sponsored ? "#FD3E6014" : "#E8E9F5B2",
                        width: "100%",
                        margin: !arabic ? "0" : "0px 0 0 20px",
                      }}
                      onClick={() => {
                        setsponsored(true);
                      }}
                    >
                      {t("common:sponsored")}
                    </OptionBtn>
                    <OptionBtn
                      className=" "
                      arabic={arabic}
                      justifyContent="center"
                      style={{
                        color: sponsored === false ? "#FD3E60" : "#191d23",
                        backgroundColor:
                          sponsored === false ? "#FD3E6014" : "#E8E9F5B2",
                        width: "100%",
                        margin: arabic ? "0" : "0px 0 0 20px",
                      }}
                      onClick={() => {
                        setsponsored(false);
                        accomplished.push("pagenine");
                        nextPage("10");
                      }}
                    >
                      {t("common:selfFunded")}
                    </OptionBtn>
                  </div>
                </>
                {sponsored ? (
                  <div
                    style={{ paddingTop: 40 }}
                    className="d-flex w-100 flex-column justify-content-center align-items-center"
                  >
                    <QuestionText
                      style={{
                        marginBottom: 15,
                        textAlign: arabic ? "right" : "left",
                        width: "100%",
                        fontWeight: 500,
                      }}
                      arabic={arabic}
                    >
                      {t("common:whoIsSponsoring")}
                    </QuestionText>
                    <InputComponent
                      placeholder={``}
                      name="sponsoredBy"
                      onChange={(e: any) => setsponsoredBy(e.target.value)}
                      type="text"
                      value={sponsoredBy}
                      autoComplete="off"
                      arabic={arabic}
                    />
                    <ButtonComponent
                      className=""
                      disableBtnStyle={!sponsoredBy}
                      label={t("common:continue")}
                      onClick={() => {
                        accomplished.push("pagenine");
                        nextPage("10");
                      }}
                      loading={false}
                      btnType={"dark"}
                      width="392px"
                      style={{ marginTop: 36, height: 56 }}
                    />
                  </div>
                ) : null}
              </div>
            ) : page === "10" ? (
              <div className="d-flex w-100 flex-column justify-content-center align-items-center">
                <FormContainer>
                  <Formik
                    // isInitialValid={}
                    initialValues={{
                      email: "",
                      lastName: "",
                      firstName: "",
                      residence: "",
                    }}
                    onSubmit={async (
                      values: Values,
                      { setSubmitting }: FormikHelpers<Values>
                    ) => {
                      try {
                        setloading(true);
                        const conVisted = countryVisited.filter(
                          (a: any) => a.status === true
                        );
                        await createLeads({
                          email: values.email,
                          fullname: `${values.firstName} ${values.lastName}`,
                          phoneNo: formatPhoneNumberIntl(phoneNo),
                          residence: selectedResidence,
                          destination: destination,
                          iltesScore: iltesScore,
                          degree: degree,
                          subjects: majors,
                          sponsoredBy: sponsoredBy,
                          sponsored: sponsored,
                          nationality: selectedCountry,
                          engLevel: engLevel || "N/A",
                          parentsIncome: moreThan50000,
                          countriesTraveled: conVisted.map(
                            (z: any) => z.country
                          ),
                          cgpa: cgpa,
                          previousSchool: previousSchool,
                          webUrl: window.location.href,
                        });
                        showAnimation(true);
                        const qualified = moreThan50000;
                        navigate(`/${i18n.language}/results`, {
                          state: { degree, qualified },
                        });
                        setloading(false);
                        setPage("1");
                        resetValues();
                      } catch (error: any) {
                        setloading(false);
                        alert(error.response.data.message);
                      }
                    }}
                  >
                    {({ values, errors, touched }) => (
                      <Form
                        style={{ width: "70%" }}
                        className="d-flex w-100 flex-column"
                      >
                        <Row>
                          {arabic ? (
                            <>
                              <Col
                                className="pc-view"
                                lg={6}
                                style={{ marginBottom: 12 }}
                              >
                                <Label arabic={arabic}>
                                  {t("common:lastName")}
                                </Label>
                                <FormInput
                                  className=" "
                                  id="lastName"
                                  style={{
                                    borderRadius: 3,
                                    marginBottom: 0,
                                    textAlign: arabic ? "right" : "left",
                                    direction: arabic ? "rtl" : "ltr",
                                    fontFamily: arabic ? "Cairo" : "Dm Sans",
                                  }}
                                  name="lastName"
                                  placeholder={`${t(
                                    "common:lastNamePaceholder"
                                  )}`}
                                  type="text"
                                />
                              </Col>
                              <Col
                                className="pc-view"
                                lg={6}
                                style={{ marginBottom: 12 }}
                              >
                                <Label arabic={arabic}>
                                  {t("common:firstName")}
                                </Label>
                                <FormInput
                                  className=" "
                                  id="firstName"
                                  style={{
                                    borderRadius: 3,
                                    marginBottom: 0,
                                    textAlign: arabic ? "right" : "left",
                                    direction: arabic ? "rtl" : "ltr",
                                    fontFamily: arabic ? "Cairo" : "Dm Sans",
                                  }}
                                  name="firstName"
                                  placeholder={`${t(
                                    "common:firstNamePaceholder"
                                  )}`}
                                  type="text"
                                />
                              </Col>
                            </>
                          ) : (
                            <>
                              <Col
                                className="pc-view"
                                lg={6}
                                style={{ marginBottom: 12 }}
                              >
                                <Label arabic={arabic}>
                                  {t("common:firstName")}
                                </Label>
                                <FormInput
                                  className=" "
                                  id="firstName"
                                  style={{
                                    borderRadius: 3,
                                    marginBottom: 0,
                                    textAlign: arabic ? "right" : "left",
                                    direction: arabic ? "rtl" : "ltr",
                                    fontFamily: arabic ? "Cairo" : "Dm Sans",
                                  }}
                                  name="firstName"
                                  placeholder={`${t(
                                    "common:firstNamePaceholder"
                                  )}`}
                                  type="text"
                                />
                              </Col>
                              <Col
                                className="pc-view"
                                lg={6}
                                style={{ marginBottom: 12 }}
                              >
                                <Label arabic={arabic}>
                                  {t("common:lastName")}
                                </Label>
                                <FormInput
                                  className=" "
                                  id="lastName"
                                  style={{
                                    borderRadius: 3,
                                    marginBottom: 0,
                                    textAlign: arabic ? "right" : "left",
                                    direction: arabic ? "rtl" : "ltr",
                                    fontFamily: arabic ? "Cairo" : "Dm Sans",
                                  }}
                                  name="lastName"
                                  placeholder={`${t(
                                    "common:lastNamePaceholder"
                                  )}`}
                                  type="text"
                                />
                              </Col>
                            </>
                          )}
                          <Col
                            className="mobile-view"
                            lg={6}
                            style={{ marginBottom: 12 }}
                          >
                            <Label arabic={arabic}>
                              {t("common:firstName")}
                            </Label>
                            <FormInput
                              className=" "
                              id="firstName"
                              style={{
                                borderRadius: 3,
                                marginBottom: 0,
                                textAlign: arabic ? "right" : "left",
                                direction: arabic ? "rtl" : "ltr",
                                fontFamily: arabic ? "Cairo" : "Dm Sans",
                              }}
                              name="firstName"
                              placeholder={`${t("common:firstNamePaceholder")}`}
                              type="text"
                            />
                          </Col>
                          <Col
                            className="mobile-view"
                            lg={6}
                            style={{ marginBottom: 12 }}
                          >
                            <Label arabic={arabic}>
                              {t("common:lastName")}
                            </Label>
                            <FormInput
                              className=" "
                              id="lastName"
                              style={{
                                borderRadius: 3,
                                marginBottom: 0,
                                textAlign: arabic ? "right" : "left",
                                direction: arabic ? "rtl" : "ltr",
                                fontFamily: arabic ? "Cairo" : "Dm Sans",
                              }}
                              name="lastName"
                              placeholder={`${t("common:lastNamePaceholder")}`}
                              type="text"
                            />
                          </Col>
                          <Col lg={12} style={{ marginBottom: 12 }}>
                            <Label arabic={arabic}>{t("common:email")}</Label>
                            <FormInput
                              className=" "
                              id="email"
                              style={{
                                borderRadius: 3,
                                marginBottom: 0,
                                textAlign: arabic ? "right" : "left",
                                direction: arabic ? "rtl" : "ltr",
                                fontFamily: arabic ? "Cairo" : "Dm Sans",
                              }}
                              name="email"
                              placeholder={`${t("common:emailPaceholder")}`}
                              type="email"
                            />
                          </Col>

                          <Col lg={12} style={{ marginBottom: 12 }}>
                            <Label arabic={arabic}>{t("common:phoneNo")}</Label>
                            <PhoneInput
                              international={false}
                              defaultCountry="KW"
                              countries={filteredCountries}
                              value={phoneNo}
                              className={
                                arabic ? "phoneInput-ar" : "phoneInput-en"
                              }
                              onChange={(e: any) => setphoneNo(e)}
                              // countryCallingCode={false}
                              style={{
                                direction: arabic ? "rtl" : "ltr",
                              }}
                            />
                          </Col>
                          <Col lg={12}>
                            <Label arabic={arabic}>
                              {t("common:residence")}
                            </Label>
                            <CountryInputComponent
                              formInput={true}
                              className=""
                              recommendedCountries={[]}
                              selectedCountry={selectedResidence}
                              setsearchText={setsearchText}
                              setselectedCountry={setselectedResidence}
                              arabic={arabic}
                              searchCountry={searchCountry}
                              countries={countries}
                              setcountries={setcountries}
                              searchText={searchText}
                              placeholder={`${t("common:residencePaceholder")}`}
                              loading={loading}
                              setRecommendedCountries={setrecommendedCountries}
                            />
                          </Col>
                        </Row>
                        <ButtonComponent
                          label={t("common:obtainDocs")}
                          loading={loading}
                          btnType={"dark"}
                          disableBtnStyle={
                            !phoneNo ||
                            !values.email ||
                            !values.firstName ||
                            !values.lastName ||
                            !Object.keys(selectedResidence).length
                          }
                          width="100%"
                          style={{
                            marginTop: 21,
                            height: 56,
                          }}
                        />
                      </Form>
                    )}
                  </Formik>
                </FormContainer>
              </div>
            ) : null}
          </div>
        ) : (
          <div className="w-100 res-con-width">
            {page === "1" ? (
              <div className="d-flex w-100 flex-column justify-content-center align-items-center">
                <QuestionText className="  " arabic={arabic}>
                  {t("common:question1")}
                </QuestionText>
                <div
                  className="d-flex flex-row w-100 justify-content-center"
                  style={{
                    paddingBottom: 15,
                    direction: arabic ? "rtl" : "ltr",
                  }}
                >
                  <OptionBtn
                    className="  "
                    arabic={arabic}
                    justifyContent="space-between"
                    style={{
                      color:
                        destination.en_name === "UK" ? "#FD3E60" : "#191d23",
                      backgroundColor:
                        destination.en_name === "UK"
                          ? "#FD3E6014"
                          : "#E8E9F5B2",
                      width: "100%",
                      margin: !arabic ? "0" : "0px 0 0 20px",
                      direction: arabic ? "rtl" : "ltr",
                    }}
                    onClick={() =>
                      onChangeDestination({
                        ar_name: "بريطانيا",
                        en_name: "UK",
                      })
                    }
                  >
                    <div
                      style={{
                        fontSize: 24,
                        position: "relative",
                        left: arabic ? 10 : 0,
                        right: arabic ? 0 : 10,
                      }}
                    >
                      🇬🇧
                    </div>{" "}
                    {t("common:uk")}
                  </OptionBtn>
                  <OptionBtn
                    className="  "
                    arabic={arabic}
                    justifyContent="space-between"
                    style={{
                      color:
                        destination.en_name === "US" ? "#FD3E60" : "#191d23",
                      backgroundColor:
                        destination.en_name === "US"
                          ? "#FD3E6014"
                          : "#E8E9F5B2",
                      width: "100%",
                      margin: arabic ? "0" : "0px 0 0 20px",
                    }}
                    onClick={() =>
                      onChangeDestination({ ar_name: "أمريكا", en_name: "US" })
                    }
                  >
                    <div />
                    <div
                      style={{
                        fontSize: 24,
                        position: "relative",
                        left: arabic ? 10 : 0,
                        right: arabic ? 0 : 10,
                      }}
                    >
                      🇺🇸
                    </div>{" "}
                    {t("common:us")}
                  </OptionBtn>
                </div>
                <div
                  className="d-flex flex-row w-100 justify-content-center"
                  style={{ direction: arabic ? "rtl" : "ltr" }}
                >
                  <OptionBtn
                    className="  "
                    arabic={arabic}
                    justifyContent="space-between"
                    style={{
                      color:
                        destination.en_name === "Australia"
                          ? "#FD3E60"
                          : "#191d23",
                      backgroundColor:
                        destination.en_name === "Australia"
                          ? "#FD3E6014"
                          : "#E8E9F5B2",
                      width: "100%",
                      margin: !arabic ? "0" : "0px 0 0 20px",
                    }}
                    onClick={() =>
                      onChangeDestination({
                        ar_name: "أستراليا",
                        en_name: "Australia",
                      })
                    }
                  >
                    <div />
                    <div
                      style={{
                        fontSize: 24,
                        position: "relative",
                        left: arabic ? 10 : 0,
                        right: arabic ? 0 : 10,
                      }}
                    >
                      🇦🇺
                    </div>{" "}
                    {t("common:aus")}
                  </OptionBtn>
                  <OptionBtn
                    className="  "
                    arabic={arabic}
                    justifyContent="space-between"
                    style={{
                      color:
                        destination.en_name === "Others"
                          ? "#FD3E60"
                          : "#191d23",
                      backgroundColor:
                        destination.en_name === "Others"
                          ? "#FD3E6014"
                          : "#E8E9F5B2",
                      width: "100%",
                      margin: arabic ? "0" : "0px 0 0 20px",
                    }}
                    onClick={() =>
                      onChangeDestination({
                        ar_name: "غيرها",
                        en_name: "Others",
                      })
                    }
                  >
                    <div />
                    <div
                      style={{
                        fontSize: 24,
                        position: "relative",
                        left: arabic ? 10 : 0,
                        right: arabic ? 0 : 10,
                      }}
                    >
                      🌏
                    </div>{" "}
                    {t("common:other")}
                  </OptionBtn>
                </div>
              </div>
            ) : page === "2" ? (
              <div className="  d-flex flex-column justify-content-center align-items-center">
                <QuestionText arabic={arabic}>
                  {t("common:question2")}
                </QuestionText>

                <CountryInputComponent
                  selectedCountry={selectedCountry}
                  setsearchText={setsearchText}
                  setselectedCountry={setselectedCountry}
                  onClickNext={(country: any) => {
                    if (selectedCountry) {
                      accomplished.push("pagetwo");
                      nextPage("3");
                      seterrorMessage("");
                      setcountries([]);
                      setsearchText("");

                      setcheckQualified(contryCheckQualified(country));
                    } else {
                      seterrorMessage("Select country to continue");
                    }
                  }}
                  arabic={arabic}
                  searchCountry={searchCountry}
                  countries={countries}
                  recommendedCountries={recommendedCountries.slice(0, 5)}
                  setcountries={setcountries}
                  searchText={searchText}
                  placeholder={`${t("common:nationalityPlaceholder")}`}
                  loading={loading}
                  setRecommendedCountries={setrecommendedCountries}
                />

                {errorMessage ? (
                  <ErrorMessage>{errorMessage}</ErrorMessage>
                ) : null}
              </div>
            ) : page === "3" ? (
              <div className="d-flex flex-column  w-100 justify-content-center align-items-center">
                <QuestionText arabic={arabic}>
                  {t("common:question3")}
                </QuestionText>
                <div
                  className="d-flex flex-row w-100 justify-content-center"
                  style={{
                    direction: arabic ? "rtl" : "ltr",
                    paddingBottom: 15,
                  }}
                >
                  <OptionBtn
                    className=" "
                    justifyContent="center"
                    arabic={arabic}
                    style={{
                      color:
                        degree.en_name === "Bachelor's" ? "#FD3E60" : "#191d23",
                      backgroundColor:
                        degree.en_name === "Bachelor's"
                          ? "#FD3E6014"
                          : "#E8E9F5B2",
                      width: "100%",
                      margin: !arabic ? "0" : "0px 0 0 20px",
                    }}
                    onClick={() =>
                      onChangeDegree({
                        en_name: "Bachelor's",
                        ar_name: "البكلوريوس",
                      })
                    }
                  >
                    {t("common:bachelor")}
                  </OptionBtn>
                  <OptionBtn
                    className=" "
                    style={{
                      color:
                        degree.en_name === "Master's" ? "#FD3E60" : "#191d23",
                      backgroundColor:
                        degree.en_name === "Master's"
                          ? "#FD3E6014"
                          : "#E8E9F5B2",
                      width: "100%",
                      margin: arabic ? "0" : "0px 0 0 20px",
                    }}
                    justifyContent="center"
                    arabic={arabic}
                    onClick={() =>
                      onChangeDegree({
                        en_name: "Master's",
                        ar_name: "الماجستير",
                      })
                    }
                  >
                    {t("common:masters")}
                  </OptionBtn>
                </div>
                <div
                  style={{
                    direction: arabic ? "rtl" : "ltr",
                  }}
                  className="d-flex flex-row w-100 justify-content-center"
                >
                  <OptionBtn
                    className=" "
                    style={{
                      color: degree.en_name === "PhD" ? "#FD3E60" : "#191d23",
                      backgroundColor:
                        degree.en_name === "PhD" ? "#FD3E6014" : "#E8E9F5B2",
                      width: "100%",
                      margin: !arabic ? "0" : "0px 0 0 20px",
                    }}
                    justifyContent="center"
                    arabic={arabic}
                    onClick={() =>
                      onChangeDegree({
                        en_name: "PhD",
                        ar_name: "الدكتوراة",
                      })
                    }
                  >
                    {t("common:phd")}
                  </OptionBtn>
                  <OptionBtn
                    className=" "
                    justifyContent="center"
                    style={{
                      color:
                        degree.en_name === "English Language"
                          ? "#FD3E60"
                          : "#191d23",
                      backgroundColor:
                        degree.en_name === "English Language"
                          ? "#FD3E6014"
                          : "#E8E9F5B2",
                      width: "100%",
                      margin: arabic ? "0" : "0px 0 0 20px",
                    }}
                    arabic={arabic}
                    onClick={() =>
                      onChangeDegree({
                        en_name: "English Language",
                        ar_name: "لغة انجليزية",
                      })
                    }
                  >
                    {t("common:english_lang")}
                  </OptionBtn>
                </div>
                {Object.keys(degree).length ? (
                  <div
                    style={{ paddingTop: 40 }}
                    className="d-flex w-100 flex-column justify-content-center align-items-center"
                  >
                    <QuestionText
                      arabic={arabic}
                      style={{
                        marginBottom: 15,
                        textAlign: arabic ? "right" : "left",
                        width: "100%",
                        fontWeight: 500,
                      }}
                    >
                      {t("common:question4")}
                    </QuestionText>
                    <InputComponent
                      placeholder={`${t("common:majorPlaceholder")}`}
                      name="name"
                      onChange={(e: any) => setmajors(e.target.value)}
                      type="text"
                      value={majors}
                      arabic={arabic}
                      autoComplete="off"
                    />
                    {errorMessage ? (
                      <ErrorMessage>{errorMessage}</ErrorMessage>
                    ) : null}
                    {majors ? (
                      <ButtonComponent
                        label={t("common:continue")}
                        onClick={() => {
                          setcountries([]);
                          if (majors) {
                            accomplished.push("pagethree");
                            nextPage("4");
                            seterrorMessage("");
                          } else {
                            seterrorMessage("Select subject to continue");
                          }
                        }}
                        loading={false}
                        width="392px"
                        btnType={"dark"}
                        style={{ marginTop: 36, height: 56 }}
                      />
                    ) : null}
                  </div>
                ) : null}
              </div>
            ) : page === "4" ? (
              <div className="d-flex flex-column justify-content-center align-items-center">
                <QuestionText arabic={arabic}>
                  {t("common:question9Qualified")}
                </QuestionText>

                <div
                  className="d-flex flex-row w-100 justify-content-center"
                  style={{
                    direction: arabic ? "rtl" : "ltr",
                  }}
                >
                  <OptionBtn
                    className=""
                    style={{
                      color: takenIltes ? "#FD3E60" : "#191d23",
                      backgroundColor: takenIltes ? "#FD3E6014" : "#E8E9F5B2",
                      width: "100%",
                      margin: !arabic ? "0" : "0px 0 0 20px",
                    }}
                    arabic={arabic}
                    justifyContent="center"
                    onClick={() => {
                      settakenIltes(true);
                    }}
                  >
                    {t("common:yes")}
                  </OptionBtn>
                  <OptionBtn
                    className=" "
                    arabic={arabic}
                    style={{
                      color: takenIltes === false ? "#FD3E60" : "#191d23",
                      backgroundColor:
                        takenIltes === false ? "#FD3E6014" : "#E8E9F5B2",
                      width: "100%",
                      marginRight: 0,
                      margin: arabic ? "0" : "0px 0 0 20px",
                    }}
                    justifyContent="center"
                    onClick={() => {
                      settakenIltes(false);
                      // accomplished.push("pageeight");
                      // nextPage("8");
                    }}
                  >
                    {t("common:no")}
                  </OptionBtn>
                </div>

                {takenIltes === false ? (
                  <div style={{ paddingTop: 40 }}>
                    <QuestionText
                      arabic={arabic}
                      style={{
                        marginBottom: 15,
                        textAlign: arabic ? "right" : "left",
                        width: "100%",
                        fontWeight: 500,
                      }}
                    >
                      {t("common:whatsYourEngLevel")}
                    </QuestionText>

                    <div
                      className="d-flex flex-row w-100 justify-content-center"
                      style={{
                        direction: arabic ? "rtl" : "ltr",
                        paddingBottom: 15,
                      }}
                    >
                      <OptionBtn
                        className=""
                        style={{
                          color:
                            engLevel === "beginner" ? "#FD3E60" : "#191d23",
                          backgroundColor:
                            engLevel === "beginner" ? "#FD3E6014" : "#E8E9F5B2",
                          width: "100%",
                          margin: !arabic ? "0" : "0px 0 0 20px",
                        }}
                        width="170px"
                        arabic={arabic}
                        justifyContent="center"
                        onClick={() => {
                          setengLevel("beginner");
                          accomplished.push("pagefour");
                          nextPage("5");
                        }}
                      >
                        {t("common:beginner")}
                      </OptionBtn>
                      <OptionBtn
                        className=" "
                        width="170px"
                        arabic={arabic}
                        justifyContent="center"
                        style={{
                          color:
                            engLevel === "lowerIntermediate"
                              ? "#FD3E60"
                              : "#191d23",
                          backgroundColor:
                            engLevel === "lowerIntermediate"
                              ? "#FD3E6014"
                              : "#E8E9F5B2",
                          margin: arabic ? "0" : "0px 0 0 20px",
                          width: "100%",
                        }}
                        onClick={() => {
                          setengLevel("lowerIntermediate");
                          accomplished.push("pagefour");
                          nextPage("5");
                        }}
                      >
                        {t("common:lowerIntermediate")}
                      </OptionBtn>
                    </div>
                    <div
                      className="d-flex flex-row w-100 justify-content-center"
                      style={{
                        direction: arabic ? "rtl" : "ltr",
                        paddingBottom: 15,
                      }}
                    >
                      <OptionBtn
                        className=" "
                        width="170px"
                        arabic={arabic}
                        justifyContent="center"
                        style={{
                          color:
                            engLevel === "intermediate" ? "#FD3E60" : "#191d23",
                          backgroundColor:
                            engLevel === "intermediate"
                              ? "#FD3E6014"
                              : "#E8E9F5B2",
                          width: "100%",
                          margin: !arabic ? "0" : "0px 0 0 20px",
                        }}
                        onClick={() => {
                          setengLevel("intermediate");
                          accomplished.push("pagefour");
                          nextPage("5");
                        }}
                      >
                        {t("common:intermediate")}
                      </OptionBtn>
                      <OptionBtn
                        className=" "
                        width="170px"
                        arabic={arabic}
                        justifyContent="center"
                        style={{
                          color:
                            engLevel === "upperIntermediate"
                              ? "#FD3E60"
                              : "#191d23",
                          backgroundColor:
                            engLevel === "upperIntermediate"
                              ? "#FD3E6014"
                              : "#E8E9F5B2",
                          margin: arabic ? "0" : "0px 0 0 20px",
                          width: "100%",
                        }}
                        onClick={() => {
                          setengLevel("upperIntermediate");
                          accomplished.push("pagefour");
                          nextPage("5");
                        }}
                      >
                        {t("common:upperIntermediate")}
                      </OptionBtn>
                    </div>
                    <div
                      className="d-flex flex-row w-100 justify-content-center"
                      style={{
                        direction: arabic ? "rtl" : "ltr",
                      }}
                    >
                      <OptionBtn
                        className=" "
                        width="170px"
                        arabic={arabic}
                        justifyContent="center"
                        style={{
                          color:
                            engLevel === "advanced" ? "#FD3E60" : "#191d23",
                          backgroundColor:
                            engLevel === "advanced" ? "#FD3E6014" : "#E8E9F5B2",
                          width: "100%",
                          margin: !arabic ? "0" : "0px 0 0 20px",
                        }}
                        onClick={() => {
                          setengLevel("advanced");
                          accomplished.push("pagefour");
                          nextPage("5");
                        }}
                      >
                        {t("common:advanced")}
                      </OptionBtn>
                      <div style={{ opacity: "0" }}>
                        <OptionBtn
                          className=" "
                          width="170px"
                          arabic={arabic}
                          justifyContent="center"
                          onClick={() => {}}
                          style={{
                            margin: arabic ? "0" : "0px 0 0 20px",
                          }}
                        >
                          {t("common:advanced")}
                        </OptionBtn>
                      </div>
                    </div>
                  </div>
                ) : takenIltes === true ? (
                  <div className=" w-100" style={{ paddingTop: 40 }}>
                    <QuestionText
                      arabic={arabic}
                      style={{
                        marginBottom: 15,
                        textAlign: arabic ? "right" : "left",
                        width: "100%",
                        fontWeight: 500,
                      }}
                    >
                      {t("common:whatsYourScore")}
                    </QuestionText>
                    <InputComponent
                      placeholder={``}
                      name="iltesScore"
                      onChange={(e: any) => setiltesScore(e.target.value)}
                      type="number"
                      style={{ width: "100%" }}
                      value={iltesScore}
                      autoComplete="off"
                      arabic={arabic}
                    />
                    <ButtonComponent
                      className=" "
                      disableBtnStyle={!iltesScore}
                      label={t("common:continue")}
                      onClick={() => {
                        accomplished.push("pagefour");
                        nextPage("5");
                      }}
                      loading={loading}
                      btnType={"dark"}
                      width="392px"
                      style={{ marginTop: 36, height: 56 }}
                    />
                  </div>
                ) : null}
              </div>
            ) : page === "5" ? (
              <div className="d-flex flex-column justify-content-center align-items-center">
                <QuestionText arabic={arabic}>
                  {t("common:question9NotQualified")}
                </QuestionText>

                <UniversityInputComponent
                  selectedUniversity={selectedUniversity}
                  university={universitiesRe}
                  setselectedUniversity={setselectedUniversity}
                  loading={loadingUni}
                  setsearchText={setsearchText}
                  arabic={arabic}
                  universities={universities}
                  searchText={searchText}
                  searchUniversity={(e: any) => searchUniversity(e)}
                  t={t}
                  setuniversities={setuniversities}
                  seterrorMessage={seterrorMessage}
                  onClickNext={() => {
                    nextPage("6");
                    accomplished.push("pagefive");
                  }}
                  error={
                    errorMessage ? (
                      <ErrorMessage style={{ marginBottom: 5 }}>
                        {errorMessage}
                      </ErrorMessage>
                    ) : null
                  }
                />

                {selectedUniversity.length ? (
                  <ButtonComponent
                    label={t("common:continue")}
                    onClick={() => {
                      nextPage("6");
                      accomplished.push("pagefive");
                    }}
                    loading={loading}
                    btnType={"dark"}
                    width="392px"
                    style={{ marginTop: 20, height: 56 }}
                  />
                ) : null}
              </div>
            ) : page === "6" ? (
              <div className="d-flex flex-column justify-content-center align-items-center">
                <>
                  <QuestionText arabic={arabic}>
                    {t("common:sponsorQuestion")}
                  </QuestionText>

                  <div
                    className="d-flex flexrow w-100"
                    style={{
                      direction: arabic ? "rtl" : "ltr",
                    }}
                  >
                    <OptionBtn
                      className=""
                      // selected={takenIltes}
                      arabic={arabic}
                      justifyContent="center"
                      style={{
                        color: sponsored ? "#FD3E60" : "#191d23",
                        backgroundColor: sponsored ? "#FD3E6014" : "#E8E9F5B2",
                        width: "100%",
                        margin: !arabic ? "0" : "0px 0 0 20px",
                      }}
                      onClick={() => {
                        setsponsored(true);
                      }}
                    >
                      {t("common:sponsored")}
                    </OptionBtn>
                    <OptionBtn
                      className=" "
                      arabic={arabic}
                      justifyContent="center"
                      style={{
                        color: sponsored === false ? "#FD3E60" : "#191d23",
                        backgroundColor:
                          sponsored === false ? "#FD3E6014" : "#E8E9F5B2",
                        width: "100%",
                        marginRight: 0,
                        margin: arabic ? "0" : "0px 0 0 20px",
                      }}
                      onClick={() => {
                        setsponsored(false);
                        accomplished.push("pagesix");
                        nextPage("7");
                      }}
                    >
                      {t("common:selfFunded")}
                    </OptionBtn>
                  </div>
                </>
                {sponsored ? (
                  <div
                    style={{ paddingTop: 40 }}
                    className="d-flex   w-100 flex-column justify-content-center align-items-center"
                  >
                    <QuestionText
                      style={{
                        marginBottom: 15,
                        textAlign: arabic ? "right" : "left",
                        width: "100%",
                        fontWeight: 500,
                      }}
                      arabic={arabic}
                    >
                      {t("common:whoIsSponsoring")}
                    </QuestionText>
                    <InputComponent
                      placeholder={``}
                      name="sponsoredBy"
                      onChange={(e: any) => setsponsoredBy(e.target.value)}
                      type="text"
                      value={sponsoredBy}
                      autoComplete="off"
                      arabic={arabic}
                    />
                    <ButtonComponent
                      className=""
                      disableBtnStyle={!sponsoredBy}
                      label={t("common:continue")}
                      onClick={() => {
                        accomplished.push("pagesix");
                        nextPage("7");
                      }}
                      loading={false}
                      btnType={"dark"}
                      width="392px"
                      style={{ marginTop: 36, height: 56 }}
                    />
                  </div>
                ) : null}
              </div>
            ) : page === "7" ? (
              <div className="d-flex w-100 flex-column justify-content-center align-items-center">
                <FormContainer>
                  <Formik
                    initialValues={{
                      email: "",
                      lastName: "",
                      firstName: "",
                      residence: "",
                    }}
                    onSubmit={async (
                      values: Values,
                      { setSubmitting }: FormikHelpers<Values>
                    ) => {
                      setloading(true);

                      await createLeads({
                        email: values.email,
                        fullname: `${values.firstName} ${values.lastName}`,
                        phoneNo: formatPhoneNumberIntl(phoneNo),
                        residence: selectedResidence,
                        destination: destination,
                        degree: degree,
                        selectedUniversity: selectedUniversity.length
                          ? selectedUniversity
                          : "not sure",
                        subjects: majors,
                        nationality: selectedCountry,
                        iltesScore: iltesScore,
                        engLevel: engLevel || "N/A",
                        sponsoredBy: sponsoredBy,
                        sponsored: sponsored,
                        cgpa: cgpa,
                        webUrl: window.location.href,
                      });
                      showAnimation(true);
                      const qualified = true;
                      navigate(`/${i18n.language}/results`, {
                        state: { degree, qualified },
                      });
                      setPage("1");
                      resetValues();
                      setloading(false);
                    }}
                  >
                    {({ values, errors, touched }) => (
                      <Form
                        style={{ width: "70%" }}
                        className="d-flex w-100 flex-column"
                      >
                        <Row>
                          {arabic ? (
                            <>
                              <Col
                                className="pc-view"
                                lg={6}
                                style={{ marginBottom: 12 }}
                              >
                                <Label arabic={arabic}>
                                  {t("common:lastName")}
                                </Label>
                                <FormInput
                                  className=" "
                                  id="lastName"
                                  style={{
                                    borderRadius: 3,
                                    marginBottom: 0,
                                    textAlign: arabic ? "right" : "left",
                                    direction: arabic ? "rtl" : "ltr",
                                    fontFamily: arabic ? "Cairo" : "Dm Sans",
                                  }}
                                  name="lastName"
                                  placeholder={`${t(
                                    "common:lastNamePaceholder"
                                  )}`}
                                  type="text"
                                />
                              </Col>
                              <Col
                                className="pc-view"
                                lg={6}
                                style={{ marginBottom: 12 }}
                              >
                                <Label arabic={arabic}>
                                  {t("common:firstName")}
                                </Label>
                                <FormInput
                                  className=" "
                                  id="firstName"
                                  style={{
                                    borderRadius: 3,
                                    marginBottom: 0,
                                    textAlign: arabic ? "right" : "left",
                                    direction: arabic ? "rtl" : "ltr",
                                    fontFamily: arabic ? "Cairo" : "Dm Sans",
                                  }}
                                  name="firstName"
                                  placeholder={`${t(
                                    "common:firstNamePaceholder"
                                  )}`}
                                  type="text"
                                />
                              </Col>
                            </>
                          ) : (
                            <>
                              <Col
                                className="pc-view"
                                lg={6}
                                style={{ marginBottom: 12 }}
                              >
                                <Label arabic={arabic}>
                                  {t("common:firstName")}
                                </Label>
                                <FormInput
                                  className=" "
                                  id="firstName"
                                  style={{
                                    borderRadius: 3,
                                    marginBottom: 0,
                                    textAlign: arabic ? "right" : "left",
                                    direction: arabic ? "rtl" : "ltr",
                                    fontFamily: arabic ? "Cairo" : "Dm Sans",
                                  }}
                                  name="firstName"
                                  placeholder={`${t(
                                    "common:firstNamePaceholder"
                                  )}`}
                                  type="text"
                                />
                              </Col>
                              <Col
                                className="pc-view"
                                lg={6}
                                style={{ marginBottom: 12 }}
                              >
                                <Label arabic={arabic}>
                                  {t("common:lastName")}
                                </Label>
                                <FormInput
                                  className=" "
                                  id="lastName"
                                  style={{
                                    borderRadius: 3,
                                    marginBottom: 0,
                                    textAlign: arabic ? "right" : "left",
                                    direction: arabic ? "rtl" : "ltr",
                                    fontFamily: arabic ? "Cairo" : "Dm Sans",
                                  }}
                                  name="lastName"
                                  placeholder={`${t(
                                    "common:lastNamePaceholder"
                                  )}`}
                                  type="text"
                                />
                              </Col>
                            </>
                          )}
                          <Col
                            className="mobile-view"
                            lg={6}
                            style={{ marginBottom: 12 }}
                          >
                            <Label arabic={arabic}>
                              {t("common:firstName")}
                            </Label>
                            <FormInput
                              className=" "
                              id="firstName"
                              style={{
                                borderRadius: 3,
                                marginBottom: 0,
                                textAlign: arabic ? "right" : "left",
                                direction: arabic ? "rtl" : "ltr",
                                fontFamily: arabic ? "Cairo" : "Dm Sans",
                              }}
                              name="firstName"
                              placeholder={`${t("common:firstNamePaceholder")}`}
                              type="text"
                            />
                          </Col>
                          <Col
                            className="mobile-view"
                            lg={6}
                            style={{ marginBottom: 12 }}
                          >
                            <Label arabic={arabic}>
                              {t("common:lastName")}
                            </Label>
                            <FormInput
                              className=" "
                              id="lastName"
                              style={{
                                borderRadius: 3,
                                marginBottom: 0,
                                textAlign: arabic ? "right" : "left",
                                direction: arabic ? "rtl" : "ltr",
                                fontFamily: arabic ? "Cairo" : "Dm Sans",
                              }}
                              name="lastName"
                              placeholder={`${t("common:lastNamePaceholder")}`}
                              type="text"
                            />
                          </Col>

                          <Col md={12} style={{ marginBottom: 12 }}>
                            <Label arabic={arabic}>{t("common:email")}</Label>
                            <FormInput
                              id="email"
                              style={{
                                borderRadius: 3,
                                marginBottom: 0,
                                textAlign: arabic ? "right" : "left",
                                direction: "ltr",
                                fontFamily: arabic ? "Cairo" : "Dm Sans",
                              }}
                              name="email"
                              placeholder={`${t("common:emailPaceholder")}`}
                              type="email"
                            />
                          </Col>

                          <Col md={12} style={{ marginBottom: 12 }}>
                            <Label arabic={arabic}>{t("common:phoneNo")}</Label>

                            <PhoneInput
                              international={false}
                              defaultCountry="KW"
                              value={phoneNo}
                              countries={filteredCountries}
                              className={
                                arabic ? "phoneInput-ar" : "phoneInput-en"
                              }
                              onChange={(e: any) => setphoneNo(e)}
                              // countryCallingCode={false}
                            />
                            {/* <PhonenoComponent /> */}
                          </Col>
                          <Col md={12}>
                            <Label arabic={arabic}>
                              {t("common:residence")}
                            </Label>
                            <CountryInputComponent
                              setRecommendedCountries={setrecommendedCountries}
                              selectedCountry={selectedResidence}
                              setsearchText={setsearchText}
                              setselectedCountry={setselectedResidence}
                              arabic={arabic}
                              loading={loading}
                              searchCountry={searchCountry}
                              countries={countries}
                              setcountries={setcountries}
                              searchText={searchText}
                              placeholder={`${t("common:residencePaceholder")}`}
                              recommendedCountries={recommendedCountries.slice(
                                0,
                                5
                              )}
                            />
                          </Col>
                        </Row>
                        <ButtonComponent
                          label={t("common:obtainDocs")}
                          loading={loading}
                          btnType={"dark"}
                          disableBtnStyle={
                            !phoneNo ||
                            !values.email ||
                            !values.firstName ||
                            !values.lastName ||
                            !Object.keys(selectedResidence).length
                          }
                          width="100%"
                          style={{ marginTop: 21, height: 56 }}
                        />
                      </Form>
                    )}
                  </Formik>
                </FormContainer>
              </div>
            ) : null}
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
};

// <>
//                 <div className="d-flex flex-column justify-content-center align-items-center">
//                   <QuestionText arabic={arabic}>
//                     {t("common:question5")}
//                   </QuestionText>

//                   <InputComponent
//                     placeholder={`${t("common:gradePlaceholder")}`}
//                     name="name"
//                     onChange={(e: any) => setcgpa(e.target.value)}
//                     type="text"
//                     className="form-input"
//                     value={cgpa}
//                     arabic={arabic}
//                     autoComplete="off"
//                   />
//                   {cgpa ? null : (
//                     <OptionBtn
//                       arabic={arabic}
//                       width={"max-content"}
//                       justifyContent="center"
//                       marginTop="15px"
//                       onClick={() => {
//                         setcgpa("Not Sure");
//                         accomplished.push("pagefive");
//                         nextPage("6");
//                         seterrorMessage("");
//                       }}
//                     >
//                       {t("common:notSure")}{" "}
//                     </OptionBtn>
//                   )}
//                   {errorMessage ? (
//                     <ErrorMessage>{errorMessage}</ErrorMessage>
//                   ) : null}
//                   {cgpa ? (
//                     <ButtonComponent
//                       label={t("common:continue")}
//                       onClick={() => {
//                         if (cgpa) {
//                           accomplished.push("pagefour");
//                           nextPage("5");
//                           seterrorMessage("");
//                         } else {
//                           seterrorMessage("Select option to continue");
//                         }
//                       }}
//                       loading={loading}
//                       btnType={"dark"}
//                       width="392px"
//                       style={{ marginTop: 36, height: 56 }}
//                     />
//                   ) : null}
//                 </div>
//               </>
